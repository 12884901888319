body {
  font-family:  Courier, monospace;
  color: #fff;
  align-items: center;
  text-align: center;
  height: 100vh;
  width: 100vw;
  background-image: url('background2.JPG')  ;
  background-size:  cover;
  margin: 0;
}


.App {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  flex-direction: column;
  align-items: center;
  background: rgba(3, 3, 5, 0.3);
}


.container {
  width: 100%;
  margin: 15% auto;
}

h1 {
  font-size: 50px;
}


@media only screen and (max-width: 600px) {

  .container {
    width: 100%;
    margin: 55% auto;
  }
  
  h1 {
    font-size: 50px;
  }

}